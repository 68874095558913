import React, { useEffect, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import getWeeklyAllocationReportColumns from './WeeklyAllocationReportColumns'
import useElementNames from 'hooks/useElementNames'

import './WeeklyAllocationReport.scss'

const WeeklyAllocationReport = ({ actualsData, accountsData = [] }) => {
  const elementNames = useElementNames()
  const [data, setData] = useState([])
  const [currentTimeRanges, setCurrentTimeRanges] = useState([])
  const [previousTimeRanges, setPreviousTimeRanges] = useState([])
  const [grouping] = React.useState(['element4', 'accountId'])
  const [columnPinning] = React.useState({
    left: ['element4', 'accountId', 'percentage']
  })

  const table = useReactTable({
    data,
    columns: getWeeklyAllocationReportColumns(currentTimeRanges, previousTimeRanges, accountsData, elementNames),
    state: {
      grouping,
      columnPinning
    },
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel()
  })

  useEffect(() => {
    setData(processAccountData(actualsData.usages))
    setCurrentTimeRanges(actualsData.currentTimeRanges)
    setPreviousTimeRanges(actualsData.previousTimeRanges)
  }, [actualsData])

  return (
    <div className="table-responsive d-flex">
      <table className="table table-fixed table-bordered table-hover table-striped">
        <thead>
          {table.getLeftHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan} className="text-nowrap">
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getLeftVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {cell.getIsGrouped() ? (
                      <>
                        <button
                          {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: {
                              cursor: row.getCanExpand() ? 'pointer' : 'normal'
                            }
                          }}
                        >
                          {row.getIsExpanded() ? (
                            <FontAwesomeIcon icon={faChevronDown} />
                          ) : (
                            <FontAwesomeIcon icon={faChevronRight} />
                          )}{' '}
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}{' '}
                          <span className="group-quantity">({row.subRows.length})</span>
                        </button>
                      </>
                    ) : cell.getIsAggregated() ? (
                      flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                    ) : cell.getIsPlaceholder() ? null : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>

      <table className="table table-bordered  table-hover table-striped">
        <thead>
          {table.getCenterHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={header.id === 'Current' || header.column.parent?.id === 'Current' ? 'current-month' : ''}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getCenterVisibleCells().map((cell) => (
                  <td key={cell.id} className={cell.column.parent.id === 'Current' ? 'current-month' : ''}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default WeeklyAllocationReport

function processAccountData(inputArray) {
  // Flatten the array and add accountId and elementMap to each breakdown
  const flattenedBreakdowns = inputArray.flatMap((item) =>
    item.breakdowns.map((breakdown) => ({
      ...breakdown,
      accountId: item.accountId,
      elementMap: item.elementMap,
      percentage: item.percentage
    }))
  )

  // Group data by element1Id, element2Id, element3Id, element4Id, accountId
  const groupedByElements = flattenedBreakdowns.reduce((acc, item) => {
    const key = `${item.elementMap.element1Id}|${item.elementMap.element2Id}|${item.elementMap.element3Id}|${item.elementMap.element4Id}|${item.accountId}`
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(item)
    return acc
  }, {})

  // Process each group
  const result = Object.entries(groupedByElements).flatMap(([elementsKey, items]) => {
    // Create a new group for combined claimed values
    const [element1Id, element2Id, element3Id, element4Id, accountId] = elementsKey.split('|')

    // Modify original items
    const modifiedItems = items.reduce((acc, item) => {
      const existingItem = acc.find(
        (i) =>
          i.elementMap.element4Id === item.elementMap.element4Id &&
          i.accountId === item.accountId &&
          i.percentage === item.percentage
      )

      if (existingItem) {
        existingItem.breakdowns.push({
          week: item.week,
          year: item.year,
          month: item.month,
          shared: item.shared,
          claimed: item.claimed,
          dateRange: item.dateRange,
          isCurrent: item.isCurrent
        })
      } else {
        acc.push({
          elementMap: item.elementMap,
          accountId: item.accountId,
          percentage: item.percentage,
          breakdowns: [
            {
              week: item.week,
              year: item.year,
              month: item.month,
              shared: item.shared,
              claimed: item.claimed,
              dateRange: item.dateRange,
              isCurrent: item.isCurrent
            }
          ]
        })
      }
      return acc
    }, [])

    const showClaimed = !(modifiedItems.length === 1 && modifiedItems[0].percentage === 100)

    // Create a new group for combined claimed values if needed
    let claimedGroup

    if (showClaimed) {
      // Calculate combined claimed values
      const claimedSum = items.reduce((acc, item) => {
        if (!acc[item.week]) {
          acc[item.week] = { claimed: 0, shared: 0 }
        }
        acc[item.week].claimed += item.claimed
        acc[item.week].shared += item.shared
        return acc
      }, {})

      claimedGroup = {
        elementMap: { element1Id, element2Id, element3Id, element4Id },
        accountId,
        percentage: 'Claimed',
        breakdowns: Object.entries(claimedSum).map(([week, values]) => ({
          week: parseInt(week),
          year: items[0].year,
          month: items[0].month,
          claimed: values.claimed,
          shared: values.shared,
          dateRange: items.find((item) => item.week.toString() === week).dateRange,
          isCurrent: items.find((item) => item.week.toString() === week).isCurrent
        }))
      }
    }

    // Return modified original items plus the new claimed group if it exists
    return claimedGroup ? [claimedGroup, ...modifiedItems] : modifiedItems
  })

  return result
}
