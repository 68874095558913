import moment from 'moment'

const saveAllocations = (
  { account, allocations, allocationYear, effectiveDate, accountType, name, reference },
  { observer, accountMapperRepoNew }
) => {
  const allocationsToPublish = allocations.map((allocation) => {
    return {
      element1Id: allocation.element1.id,
      element2Id: allocation.element2.id,
      element3Id: allocation.element3.id,
      element4Id: allocation.element4.id,
      percentage: Number(allocation.percentage)
    }
  })

  const startDate = moment(effectiveDate) || moment().startOf('year')

  accountMapperRepoNew.saveOrUpdateAllocation(
    {
      accountId: account.accountId,
      accountType,
      allocationYear,
      name,
      reference,
      startDate: startDate.format('MM-DD-YYYY'),
      allocations: allocationsToPublish
    },
    {
      success: (response) => {
        if (response.createAccountMapping.error) {
          observer.failure()
          return
        }

        observer.success()
      },
      failure: (error) => {
        observer.failure(error)
      }
    }
  )
}

export default saveAllocations
